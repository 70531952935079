import HttpClient from "../utils/http-client";
import SharedConstant from "../constants/SharedConstant";

const SharedService = {
  countryCodeList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          SharedConstant.COUNTRYCODE_LIST_URL
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  customerList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${SharedConstant.CUSTOMER_LIST}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  assignedUserList: (id, role) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${SharedConstant.ASSIGNED_USER_LIST}?id=${id}&role=${role}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  userAssignedCustomerList: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${SharedConstant.USER_ASSIGNED_CUSTOMER_LIST}`,
          values
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  campaignList: (vicidialServerIp) => {
    return new Promise(async (resolve, reject) => {
      if (!vicidialServerIp) {
        return false;
      }
      try {
        const response = await HttpClient.get(
          `${SharedConstant.CAMPAIGN_LIST}?vicidialServerIp=${vicidialServerIp}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  dispositionList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${SharedConstant.DISPOSITION_LIST_URL}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default SharedService;
