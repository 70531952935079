import React from "react";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import { APP_NAME } from "configs/AppConfig";
import { connect } from "react-redux";
import utils from "utils";
import { Grid } from "antd";
import { getLogoDynamically } from "assets/functions/MiscFunctions";

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return "auto";
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  } else {
    return `${SIDE_NAV_WIDTH}px`;
  }
};

const getLogo = (props) => {
  const { navCollapsed, logoType, userInfo } = props;
  if (logoType === "light") {
    if (navCollapsed) {
     return getLogoDynamically(userInfo?.customerid?.customerid?.[0], navCollapsed);
    }
    return getLogoDynamically(userInfo?.customerid?.customerid?.[0], navCollapsed);
  }

  if (navCollapsed) {
    return getLogoDynamically(userInfo?.customerid?.customerid?.[0], navCollapsed);
  }

  return getLogoDynamically(userInfo?.customerid?.customerid?.[0], navCollapsed);
};

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return "d-none";
  } else {
    return "logo";
  }
};

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}`, paddingLeft: 0 }}
    >
      {props.navCollapsed ? (
        <img
          src={getLogo(props)}
          alt={`${APP_NAME} logo`}
          style={{ height: "5.5rem", width: "6rem" }}
        />
      ) : (
        <img
          src={getLogo(props)}
          alt={`${APP_NAME} logo`}
          style={{ height: "5rem", width: "11rem" }}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { navCollapsed, navType } = theme;
  const { userInfo } = auth;
  return { navCollapsed, navType, userInfo };
};

export default connect(mapStateToProps)(Logo);
