import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, Select, message } from "antd";
import { vicidialLogin } from "redux/actions/Auth";
import { SharedService } from "services";

const ViciLogin = ({ vicidialLogin, userInfo, vicidialServerIp }) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [campaign, setCampaign] = useState("");
  const [campaignList, setCampaignList] = useState("");
  const [vicidialError, setVicidialError] = useState("");

  const displayError = (msg) => {
    message.error(msg);
  };
  const handleOk = () => {
    if (!campaign) {
      displayError("Please Select Campaign");
    } else {
      vicidialLogin(userInfo, campaign, vicidialServerIp);
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onCampaignChange = (value) => {
    setCampaign(value);
  };

  const { Option } = Select;

  useEffect(() => {
    const fetchInitialData = async () => {
      SharedService.campaignList(vicidialServerIp)
        .then((res) => {
          if (res.message) {
            console.log("this is that ugly error", res.message);
            setVicidialError(res.message);
          } else {
            setCampaignList(res);
          }
        })
        .catch(
          (err) =>
            err?.message &&
            console.log("this is that ugly error 2", err) &&
            setVicidialError(err.message)
        );
    };
    userInfo.role === "Agent" && fetchInitialData();
  }, [vicidialServerIp]);

  return (
    <>
      <Modal
        title="SmartDial Campaign Selection"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="350px"
        centered
      >
        {campaignList.length !== 0 ? (
          <Select
            showSearch
            allowClear
            placeholder="Select Campaign"
            optionFilterProp="label"
            style={{ width: "80%" }}
            onChange={onCampaignChange}
          >
            {campaignList.length !== 0 &&
              campaignList.map((elm, i) => (
                <Option key={i} value={elm.campaign_id} label={elm.campaign_id}>
                  {elm.campaign_id}
                </Option>
              ))}
          </Select>
        ) : (
          <h4>{vicidialError}</h4>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = ({ auth, user }) => {
  const { userInfo, message, showMessage } = auth;
  const { vicidialServerIp } = user;

  return {
    userInfo,
    message,
    showMessage,
    vicidialServerIp,
  };
};

export default connect(mapStateToProps, { vicidialLogin })(ViciLogin);
