import React, { useState, useEffect } from "react";
import { Drawer, Menu, Button, Form, Col, Row, Input, message } from "antd";
import { DynamicInput } from "components/shared-components/DynamicInput";
import { connect } from "react-redux";
import {
  hideDispositionPanel,
  setViciCallLog,
  setStartCall,
  setSelectedRow,
} from "redux/actions/Crm";
import CrmService from "services/CrmService";
import { IS_CALL_ACTIVE } from "redux/constants/Crm";
import DispositionDropdown from "components/util-components/Crm/DispositionDropdown";

const NavPanel = ({
  selectedContactRow,
  userInfo,
  isDispositionPanelVisible,
  hideDispositionPanel,
  viciCallLog,
  setStartCall,
  setSelectedRow,
  vicidialServerIp,
  startCall,
  inboundCallData
}) => {
  const [input, setInput] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  const showDrawer = () => {};

  const defaultValues = {
    name: selectedContactRow.full_name,
    account: selectedContactRow.loan_account_no,
  };

  useEffect(() => {
    const interval = setTimeout(function () {
      form.setFieldsValue(defaultValues);
    }, 0);
    return () => {
      clearInterval(interval);
    };
  }, [form, defaultValues]);

  const onClose = () => {
    hideDispositionPanel();
  };

  const onFinish = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        CrmService.disposeCall(
          userInfo,
          selectedContactRow,
          values,
          viciCallLog,
          vicidialServerIp,
          startCall,
          inboundCallData
        )
          .then((res) => {
            message.success(res);
            form.resetFields();
            hideDispositionPanel();
            setSubmitLoading(false);
            setSelectedRow("");
          })
          .catch((err) => {
            message.error(err);
            form.resetFields();
            setSubmitLoading(false);
          });
        setStartCall("", false);

        sessionStorage.removeItem(IS_CALL_ACTIVE); //For hangup Button Remove
        setInput([]);
      })

      .catch((e) => {
        setSubmitLoading(false);
        message.error("Please enter all required field ");
      });
  };

  const onDispositionChange = (value) => {
    console.log("disposition change called ", value);
    if (value === "CB") {
      setInput([
        {
          type: "date",
          name: "follow_up",
          label: "Follow-Up",
        },
      ]);
    } else if (value === "Paid" || value === "PAID") {
      setInput([
        {
          type: "date",
          name: "payment_date",
          label: "Payment Date",
          rule: [{ required: true }],
        },
        {
          type: "text",
          name: "payment_amount",
          label: "Payment Amount",
          rule: [{ required: true }],
        },
        {
          type: "select",
          name: "payment_mode",
          label: "Payment Mode",
          options: ["Online", "FE", "Branch"],
        },
      ]);
    } else if (value === "PTP") {
      console.log(value);
      setInput([
        {
          type: "date",
          name: "ptp_committed_date",
          label: "PTP Committed Date",
          rule: [{ required: true }],
        },
        {
          type: "text",
          name: "ptp_committed_amount",
          label: "Payment Committed Amount",
          rule: [{ required: true }],
        },
      ]);
    } else if (value === "PPD") {
      setInput([
        {
          type: "date",
          name: "ptp_committed_date",
          label: "PTP Committed Date",
          rule: [{ required: true }],
        },
        {
          type: "text",
          name: "ptp_committed_amount",
          label: "Payment Committed Amount",
          rule: [{ required: true }],
        },
      ]);
    } else if (value === "PU") {
      setInput([
        {
          type: "date",
          name: "ptp_committed_date",
          label: "PTP Committed Date",
          rule: [{ required: true }],
        },
        {
          type: "text",
          name: "ptp_committed_amount",
          label: "Payment Committed Amount",
          rule: [{ required: true }],
        },
      ]);
    } else if (value === "CCU") {
      setInput([
        {
          type: "date",
          name: "ptp_committed_date",
          label: "PTP Committed Date",
          rule: [{ required: true }],
        },
        {
          type: "text",
          name: "cibil_amount",
          label: "Cibil Amount",
          rule: [{ required: true }],
        },
        {
          type: "text",
          name: "emi_amount",
          label: "EMI Amount",
        },
      ]);
    } else if (value === "SETTLEMENT") {
      setInput([
        {
          type: "date",
          name: "ptp_committed_date",
          label: "PTP Committed Date",
          rule: [{ required: true }],
        },
        {
          type: "text",
          name: "settlement_amount",
          label: "Settlement Amount",
          rule: [{ required: true }],
        },
        {
          type: "text",
          name: "emi_amount",
          label: "EMI Amount",
        },
      ]);
    } else {
      setInput([]);
    }
  };

  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item onClick={showDrawer} key={0}></Menu.Item>
      </Menu>
      <Drawer
        title="Call Disposition"
        placement="right"
        width={350}
        // onClose={onClose}
        visible={isDispositionPanelVisible}
        headerStyle={{
          color: "#ed1a69",
        }}
        forceRender
      >
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          initialValues={defaultValues}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label="Name">
                <Input placeholder="Please enter name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="account"
                label="Account No"
                rules={[{ required: true, message: "Please enter account no" }]}
              >
                <Input placeholder="Please enter account no" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="module" label="Module" initialValue="Spocto">
                <Input placeholder="Please enter module" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="caller"
                label="Caller"
                initialValue={
                  userInfo ? `${userInfo.firstname} ${userInfo.lastname} ` : ""
                }
              >
                <Input placeholder="Please enter Caller" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              {/* <Form.Item
                name="disposition"
                label="Disposition"
                rules= {[
                  { required: true, message: "Please choose Disposition" },
                ]}
              > */}
              {/* <Select
                  showSearch
                  allowClear
                  placeholder="Disposition"
                  optionFilterProp="label"
                  onChange={onDispositionChange}
                >
                  {utils.dispositionList.map((elm, i) => (
                    <Option key={i} value={elm.value} label={elm.label}>
                      {elm.label}
                    </Option>
                  ))}
                </Select> */}
              <DispositionDropdown
                placeholder="Disposition"
                onChangefunction={onDispositionChange}
                rules="required"
                label="Disposition"
              />
              {/* </Form.Item> */}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <DispositionDropdown
                placeholder="Sub-Disposition"
                label="Sub-Disposition"
                name="sub_disposition"
              />
            </Col>
          </Row>
          {input.map((elm, i) => {
            return (
              <Row gutter={16} key={elm.name}>
                <Col span={24}>
                  <DynamicInput elm={elm} />
                </Col>
              </Row>
            );
          })}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "please enter description",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="please enter description"
                />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => onFinish()}
              htmlType="submit"
              style={{ marginRight: 8 }}
              loading={submitLoading}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = ({ crm, auth, user }) => {
  const {
    selectedContactRow,
    isDispositionPanelVisible,
    viciCallLog,
    startCall,
    inboundCallData
  } = crm;
  const { userInfo } = auth;
  const { vicidialServerIp } = user;
  return {
    selectedContactRow,
    userInfo,
    isDispositionPanelVisible,
    viciCallLog,
    vicidialServerIp,
    startCall,
    inboundCallData
  };
};

export default connect(mapStateToProps, {
  hideDispositionPanel,
  setViciCallLog,
  setStartCall,
  setSelectedRow,
})(NavPanel);
