import {
  LOAD_CONTACT_LIST,
  LOAD_CONTACT_TRACE_DATA,
  LOAD_CONTACT_ENGAGE_DATA,
  SET_SELECTED_CONTACT_ROW,
  SET_START_CALL,
  SHOW_DISPOSITION_PANEL,
  LOAD_CALL_HISTORY,
  UPDATE_CALL_HISTORY,
  SET_TOTAL_PAGE_ROWS,
  SHOW_LOADING,
  HIDE_LOADING,
  HIDE_DISPOSITION_PANEL,
  SET_VICI_CALL_LOG,
  LOAD_DASHBOARD,
  SET_SEARCHED_PARAMS,
  TOGGLE_CONTACT_DETAIL_EDITABLE,
  SET_MODIFIED_CONTACT_DETAIL_DATA,
  UPDATE_CONTACT_TRACE_DATA,
  SET_INBOUND_CALL_DATA,
  SET_CONTACT_INFO_DATA,
  CONTACT_INFO,
  IS_CALL_ACTIVE,
  SET_CURRENT_PAGE,
  LOAD_DISPOSITION_LIST,
} from "../constants/Crm";

const initState = {
  contactList: "",
  selectedContactRow: "",
  contactTraceData: "",
  contactEngageData: "",
  startCall: sessionStorage.getItem(IS_CALL_ACTIVE),
  isDispositionPanelVisible: false,
  callHistoryList: [],
  loading: false,
  kaleyraCallId: "",
  totalPageRows: {
    contactList: 20,
  },
  currentPage: {
    contactList: 1,
  },
  viciCallLog: "",
  dashboard: new Map(),
  dispositionlist: new Map(),
  searchedParams: {},
  isContactDetailEditable: "",
  editedContactDetail: "",
  inboundCallData: "",
  contactInfo: localStorage.getItem(CONTACT_INFO)
    ? JSON.parse(atob(localStorage.getItem(CONTACT_INFO)))
    : {
        loan_account_no: "",
        batch_no: "",
        customerid: "",
        index: "",
      },
  
};

const crm = (state = initState, action) => {
  switch (action.type) {
    case LOAD_CONTACT_LIST:
      return {
        ...state,
        contactList: action.contactList,
        //loading: false,
      };
    case LOAD_CONTACT_TRACE_DATA:
      return {
        ...state,
        contactTraceData: action.traceData,
        //loading: false,
        selectedContactRow: action.traceData,
      };
    case LOAD_CONTACT_ENGAGE_DATA:
      return {
        ...state,
        contactEngageData: action.engageData,
        loading: false,
      };
    case SET_SELECTED_CONTACT_ROW:
      return {
        ...state,
        selectedContactRow: action.selectedRow,
      };
    case SET_START_CALL: {
      return {
        ...state,
        startCall: action.payload.callType,
        kaleyraCallId: action.payload.callId,
      };
    }
    case SHOW_DISPOSITION_PANEL: {
      return {
        ...state,
        isDispositionPanelVisible: true,
      };
    }
    case HIDE_DISPOSITION_PANEL: {
      return {
        ...state,
        isDispositionPanelVisible: false,
      };
    }
    case LOAD_CALL_HISTORY: {
      return {
        ...state,
        callHistoryList: action.callHistory,
      };
    }
    case UPDATE_CALL_HISTORY: {
      const history = { _source: action.callHistory };
      return {
        ...state,
        callHistoryList: [history, ...state.callHistoryList],
      };
    }
    case SET_TOTAL_PAGE_ROWS: {
      const { key, total } = action.payload;
      return {
        ...state,
        totalPageRows: { ...state.totalPageRows, [key]: total },
      };
    }
    case SET_CURRENT_PAGE: {
      const { key, page } = action.payload;
      return {
        ...state,
        currentPage: { ...state.currentPage, [key]: page },
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_VICI_CALL_LOG: {
      return {
        ...state,
        viciCallLog: action.viciCallLog,
      };
    }
    case LOAD_DASHBOARD: {
      const dashboardData = action.dashboard;
      // const dashboardMap = new Map(utils.disposition);
      const dashboardMap = new Map(state.dispositionlist);
      dashboardData &&
        dashboardData.forEach((elm) => {
          if (dashboardMap.get(elm.name)) {
            const data = {
              ...dashboardMap.get(elm.name),
              ...elm,
            };
            dashboardMap.set(elm.name, data);
          }
        });

      return {
        ...state,
        dashboard: dashboardMap,
      };
    }
    case TOGGLE_CONTACT_DETAIL_EDITABLE: {
      return {
        ...state,
        isContactDetailEditable: action.status,
      };
    }
    case SET_MODIFIED_CONTACT_DETAIL_DATA: {
      return {
        ...state,
        editedContactDetail: action.data,
      };
    }
    case SET_SEARCHED_PARAMS: {
      let params;
      const searchParams =
        action.searchedParams !== "Total" ? action.searchedParams : "";
      if (action.searchedParams.constructor === String) {
        params = {
          disposition: searchParams,
        };
      } else if (
        Object.keys(action.searchedParams).length === 0 &&
        action.searchedParams.constructor === Object
      ) {
        params = searchParams;
      } else {
        const disposition = searchParams.disposition
          ? searchParams.disposition
          : Math.random();
        params = {
          ...state.searchedParams,
          ...searchParams,
          disposition: disposition,
        };
      }
      return {
        ...state,
        searchedParams: params,
      };
    }
    case UPDATE_CONTACT_TRACE_DATA:
      return {
        ...state,
        contactTraceData: { ...state.contactTraceData, ...action.data },
      };
    case SET_INBOUND_CALL_DATA:
      return {
        ...state,
        inboundCallData: action.data,
      };
    case SET_CONTACT_INFO_DATA:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          ...action.data,
        },
      };
    case LOAD_DISPOSITION_LIST:
      const dispositionMap = new Map();
      action.list.forEach((_disposition) => {
        dispositionMap.set(_disposition.value, {
          ..._disposition,
        });
      });
      return {
        ...state,
        dispositionlist: dispositionMap,
      };
    default:
      return state;
  }
};

export default crm;
