export const SIGNIN = 'SIGNIN'; 
export const AUTHENTICATED = 'AUTHENTICATED';
export const SHOWOTPFORM = 'SHOWOTPFORM';
export const SIGNIN_OTP = 'SIGNIN_OTP';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'AUTH_TOKEN'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'
export const USER_INFO = 'USER_INFO';
export const EXPIRES_AT = 'EXPIRES_AT';
export const IS_VICI_LOGGEDIN = 'IS_VICI_LOGGEDIN';
export const VICIDIAL_USER_INFO = 'VICIDIAL_USER_INFO';
export const USER_EMAIL = 'USER_EMAIL';
export const VICIDIAL_LOGIN = 'VICIDIAL_LOGIN';
export const VICIDIAL_AUTHENTICATED = 'VICIDIAL_AUTHENTICATED';
export const LOAD_CUSTOMER_LIST = 'LOAD_CUSTOMER_LIST';