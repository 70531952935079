import {
  LOAD_AGENT_LIST,
  LOAD_ASSIGNED_USER_LIST,
  SET_USER_ACTIVITY_STATUS,
  SET_USER_ASSIGNED_CUSTOMER_DETAIL,
  SET_BREAK_EVENT_ID
} from "../constants/User";

export const loadAgentList = (agentList) => {
  return {
    type: LOAD_AGENT_LIST,
    agentList,
  };
};

export const loadAssignedUserList = (assignedList) => {
  return {
    type: LOAD_ASSIGNED_USER_LIST,
    assignedList,
  };
};
export const setUserActivityStatus = (status) => {
  return {
    type: SET_USER_ACTIVITY_STATUS,
    status,
  };
};
export const setUserAssignedCustomerDetail = (customerDetail) => {
  return {
    type: SET_USER_ASSIGNED_CUSTOMER_DETAIL,
    customerDetail
  }
}

export const setBreakEventId = (breakEventId) => {
  return {
    type: SET_BREAK_EVENT_ID,
    breakEventId
  }
}
