export const getFormattedTime = (value) => {
  return new Date(value).toLocaleString();
};

export const getFormattedURL = (link) => {
  let formattedLink = link.replace(
    /https:\/\/spocto-cms.s3.ap-south-1.amazonaws.com\/voice\//g,
    " "
  );
  formattedLink = formattedLink.split(" ");
  for (let i = 0; i < formattedLink.length; i++) {
    if (
      formattedLink[i] === "" ||
      formattedLink[i] === " " ||
      formattedLink[i] === null ||
      formattedLink[i] === "null"
    ) {
      formattedLink.splice(i, 1);
    }
  }
  return formattedLink;
};

export const uploadErrorLog = (err) => {
  if (err.response)
    console.log(
      err.response,
      err.response.data,
      err.response.status,
      err.response.headers
    );
  else if (err.request) console.log(err.request);
  else console.log("Error", err.message);
  console.log(err.config);
};

// export const eventLogger = (event_id, event_name, event_page_name) => {
//   let logs = {
//     event_id: event_id,
//     event_name: event_name,
//     event_page_name: event_page_name,
//   };

//   //axios.post(API_PROD_URL.EVENT_LOGGER, logs);
// };

export const getCustomerLogo = (customerid) => {
  switch (customerid) {
    case "155":
      return "/img/bank-logos/bajaj-finance-ltd.webp";
    case "395":
      return "/img/bank-logos/icici_logo_long.png";
    case "405":
      return "/img/bank-logos/tata-logo.png";
    case "515":
      return "/img/bank-logos/Mashreq_Logo.jpeg";
    case "575":
      return "/img/bank-logos/RBL_Bank_SVG_Logo.svg.png";
    case "715":
      return "/img/bank-logos/Axis_Bank_logo.png";
    case "835":
      return "/img/bank-logos/tvs-credit-logo.png";
    case "2215":
      return "/img/bank-logos/SBI_Logo.jpeg";
    case "2455":
      return "/img/bank-logos/avanse_logo.png";
    case "2475":
      return "/img/bank-logos/idfc-logo.png";
    case "2505":
      return "/img/bank-logos/mahindra_home_finance.png";
    case "2525":
      return "/img/bank-logos/Emirates_NBD_logo.png";
    case "2555":
      return "/img/bank-logos/HDFC-Bank-Logo.png";
    case "2605":
      return "/img/bank-logos/tata-housing-logo.svg";
    case "2615":
      return "/img/bank-logos/lendingkart-logo.png";
    case "2625":
      return "/img/bank-logos/Standard-Chartered-logo.jpeg";
    case "2635":
      return "/img/bank-logos/Yes_Bank_logo.svg.png";
    default:
      return "";
  }
};

export const getLogoDynamically = (customerid, navCollapsed) => {
  if (navCollapsed) {
    switch (customerid) {
      case 2525:
        return "img/logos/small/emirates_logo_short.jpg";
      default:
        return "img/logos/small/spocto360_logo.png";
    }
  } else {
    switch (customerid) {
      case 2525:
        return "img/logos/large/Emirates_NBD_logo.png";
      default:
        return "img/logos/large/spocto360_logo.png";
    }
  }
};

export const randomColorGenerator = () => {
  const characters = "0123456789ABCDEF";
  let color = "#";

  for (let i = 0; i < 6; i++) {
    color += characters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export const maskLoanAccountNo = (price) => {
  let last4Digits = price.slice(-4);
  let maskedAccountNumber;
  let lengthX = "XXXX";

  maskedAccountNumber = lengthX + last4Digits;

  return maskedAccountNumber;
};

export const addComasToAmount = (amount) => {
  amount = String(amount);

  let [preDecimal, postDecimal] = amount.split(".");
  let structuredAmount = "";

  for (let i = preDecimal.length - 1; i >= 0; i--) {
    structuredAmount += preDecimal[i];
    if (preDecimal.length % 2 !== 0) {
      if (i !== preDecimal.length - 1 && i % 2 === 0) structuredAmount += ",";
    } else {
      if (i !== preDecimal.length - 1 && i % 2 !== 0) structuredAmount += ",";
    }
  }

  if (preDecimal.length % 2 !== 0)
    structuredAmount = structuredAmount.slice(0, structuredAmount.length - 1);

  structuredAmount = structuredAmount.split("").reverse().join("");

  if (!postDecimal) postDecimal = "00";
  else if (postDecimal.length === 1) postDecimal += "0";

  if (amount === "0.00") return amount;
  else return (structuredAmount = structuredAmount + "." + postDecimal);
};

export const formatFileSize = (size) => {
  if (size >= 104857.6)
    return `${Math.round((size / 1048576 + Number.EPSILON) * 100) / 100} mb`;
  else if (size >= 102.4)
    return `${Math.round((size / 1024 + Number.EPSILON) * 100) / 100} kb`;
  else return `${size} bytes`;
};

export const formatAmount = (amount) => {
  if (amount >= 10000000)
    return `₹ ${
      Math.round((amount / 10000000 + Number.EPSILON) * 100) / 100
    } Cr`;
  else if (amount >= 100000)
    return `₹ ${Math.round((amount / 100000 + Number.EPSILON) * 100) / 100} L`;
  else if (amount >= 1000)
    return `₹ ${Math.round((amount / 1000 + Number.EPSILON) * 100) / 100} K`;
};
