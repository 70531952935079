import HttpClient from "../utils/http-client";
import CrmConstant from "../constants/CrmConstant";
import { v4 as uuidv4 } from "uuid";
import { groupManagement } from "utils/user-manage";
import utils from "utils";

const CrmService = {
  contactList: (
    from,
    size,
    searchedParams,
    userInfo,
    assignedUserList,
    sortFilters
  ) => {
    return new Promise(async (resolve, reject) => {
      const getListparam = groupManagement(userInfo, assignedUserList);
      try {
        const response = await HttpClient.post(
          `${CrmConstant.CONTACT_LIST_URL}?from=${from}&size=${size}&role=${userInfo.role}`,
          {
            searchedParams: searchedParams,
            getListparam: getListparam,
            sortFilters: sortFilters,
          }
        );
        const contactlist = response.data.contactList;
        resolve(contactlist);
      } catch (error) {
        reject(error);
      }
    });
  },
  contactDetail: (accno, batch_no, customerid) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.CONTACT_DETAIL_URL}?accno=${accno}&batch_no=${batch_no}&customerid=${customerid}`
        );
        const contactdetail = response.data;
        resolve(contactdetail);
      } catch (error) {
        reject(error);
      }
    });
  },
  callingContact: (
    calltype,
    userInfo,
    row,
    mobile,
    vicidialServerIp,
    setViciCallLog
  ) => {
    const calls_id = uuidv4();
    const callback = encodeURIComponent(
      `${CrmConstant.KALEYRA_CALLBACK}?customerid=${row.customerid}&batch_no=${row.batch_no}&calls_id=${calls_id}&loan_account_no=${row.loan_account_no}&from={caller}&to={receiver}&starttime={starttime}&endtime={endtime}&duration={duration}&status={obdstatus}&recordpath={recordpath}&callerid={callerid}&location={location}&action={keypress}&id={id}`
    );
    return new Promise(async (resolve, reject) => {
      try {
        if (calltype === "kaleyra") {
          const response = await HttpClient.post(
            `${CrmConstant.KALEYRA_CALL_URL}?api_key=
            Ab3e2d13997af1cc7975363804d738109&method=dial.click2call&caller=${userInfo.mobile}&receiver=${mobile}&return=1&format=json&callback=${callback}`
          );
          resolve(response.data);
        } else if (calltype === "Outbound") {
          const response = await HttpClient.get(
            `${CrmConstant.CALL_CONTACT}?username=${userInfo.username}&mobile=${mobile}&vicidialServerIp=${vicidialServerIp}`
          );
          if (response.data.includes("SUCCESS") === true) {
            setViciCallLog(response.data);
          }
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  updateMultipleContacts: (data, searchedParams) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.CONTACT_UPDATE_URL}`,
          { data: data, searchedParams: searchedParams }
        );
        const contactdetail = response.data;
        resolve(contactdetail);
      } catch (error) {
        reject(error);
      }
    });
  },
  disposeCall: (
    userInfo,
    selectedContactRow,
    formValues,
    viciCallLog,
    vicidialServerIp,
    startCall,
    inboundCallData
  ) => {
    const getMobile = viciCallLog.split("|")[0];
    let mobile = null;

    if (startCall === "Outbound") {
      mobile = getMobile ? getMobile.slice(getMobile.length - 10) : null;
    } else if (startCall === "Inbound") {
      mobile = inboundCallData?.cust_phone1
        ? inboundCallData?.cust_phone1
        : null;
    }

    const callLogData = {
      agent: userInfo.firstname + " " + userInfo.lastname,
      username: userInfo.username,
      loan_account_no: selectedContactRow.loan_account_no,
      spoctoid: selectedContactRow.spoctoid,
      customerid: selectedContactRow.customerid,
      customer_name: selectedContactRow.customer_name,
      batch_no: selectedContactRow.batch_no,
      agent_mobile: null,
      agent_status: true,
      disposition: formValues.disposition,
      calls_id: uuidv4(),
      sub_disposition: formValues.sub_disposition,
      call_description: formValues.description.replace(/[^\w]/g, " "),
      // date_entered: new Date(),
      "@timestamp": new Date(),
      direction: startCall || "Outbound",
      channel: "spocto360",
      ptp_committed_date: formValues.ptp_committed_date,
      ptp_committed_amount: utils.validateNumber(
        formValues.ptp_committed_amount
      ),
      settlement_amount: utils.validateNumber(formValues.settlement_amount),
      emi_amount: utils.validateNumber(formValues.emi_amount),
      cibil_amount: utils.validateNumber(formValues.cibil_amount),
      payment_date: formValues.payment_date,
      payment_amount: utils.validateNumber(formValues.payment_amount),
      payment_mode: formValues.payment_mode,
      follow_up: formValues.follow_up,
      sp_engage_log_date: new Date(),
      smartdial_call_response: viciCallLog,
      disposition_update_date: new Date(),
      to: mobile,
      call_source: "spocto360",
    };

    //updateCallHistory(callLogData);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.CALL_LOG_URL}`,
          callLogData
        );
        //If call is Inbound Activate Next Call and update Assigned User in Index.
        if (startCall === "Inbound") {
          HttpClient.get(
            `${CrmConstant.PAUSE_USER}?username=${userInfo.username}&vicidialServerIp=${vicidialServerIp}&status='RESUME'`
          );
        }

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  importFile: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.FILE_IMPORT_URL}`,
          values
        );
        resolve(response.status);
      } catch (error) {
        reject(error);
      }
    });
  },
  getListOfFiles: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(`${CrmConstant.FILE_LIST_URL}`);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addFileMappings: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.FILE_MAPPING_URL}`,
          values
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  hangupCall: (userInfo, vicidialServerIp) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.HANGUP_URL}?username=${userInfo.username}&vicidialServerIp=${vicidialServerIp}`
        );

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  callHistory: (accno, from, size) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.CALL_HISTORY_URL}?accno=${accno}&from=${from}&size=${size}`
        );
        const callHistory = response.data;
        resolve(callHistory);
      } catch (error) {
        reject(error);
      }
    });
  },
  engageData: (accno, customerid, from, size) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.ENGAGE_DATA_URL}?accno=${accno}&customerid=${customerid}&from=${from}&size=${size}`
        );
        const engageData = response.data;
        resolve(engageData);
      } catch (error) {
        reject(error);
      }
    });
  },
  getNavSearch: (from, size, searchQuery) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.NAV_SEARCH_URL}?from=${from}&size=${size}&searchQuery=${searchQuery}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getDashboardData: (userInfo, assignedUserList, searchedParams) => {
    let users;
    if (userInfo.role === "Agent") {
      users = userInfo.username;
    } else if (userInfo.role === "Team Leader" || "Supervisor") {
      users = assignedUserList;
    } else users = [];

    return new Promise(async (resolve, reject) => {
      if (users) {
        try {
          const response = await HttpClient.post(
            `${CrmConstant.DASHBOARD_DATA}`,
            { user: users, role: userInfo.role, searchedParams: searchedParams }
          );
          resolve(response.data);
        } catch (error) {
          reject(error);
        }
      }
    });
  },
  updateContactDetail: (editedContactDetail, selectedContactRow) => {
    const data = {
      ...editedContactDetail,
      loan_account_no: selectedContactRow.loan_account_no,
      batch_no: selectedContactRow.batch_no,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.UPDATE_CONTACT_DETAIL}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  campaignDialMethod: ({ userInfo, vicidialServerIp, campaign }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.CAMPAIGN_DIAL_METHOD}?vicidialServerIp=${vicidialServerIp}&campaign=${campaign}`
        );
        // console.log('dial method',response.data.dial_method)
        if (
          response.data.dial_method === "RATIO" &&
          userInfo.role === "Agent"
        ) {
          await HttpClient.get(
            `${CrmConstant.PAUSE_USER}?username=${userInfo.username}&vicidialServerIp=${vicidialServerIp}&status='RESUME'`
          );
        }

        //console.log("catching" , response.data);

        resolve(response.data);
      } catch (error) {
        // console.log("dial method" , error)
        // reject(error);
      }
    });
  },
  // updateCallStatus: (userInfo, vicidialServerIp, callStatus) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const response = HttpClient.get(
  //         `${CrmConstant.PAUSE_USER}?username=${userInfo.username}&vicidialServerIp=${vicidialServerIp}&status=${callStatus}`
  //       );

  //       resolve(response.data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
};

export default CrmService;
