import HttpClient from "../utils/http-client";
import ReportsConstant from "constants/ReportsConstant";

const ReportsService = {
  getAprReport: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${ReportsConstant.GET_APR_REPORT}`,
          values
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      };
    });
  },
  getAprList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(`${ReportsConstant.APR_REPORT_LIST}`);
        resolve(response.data);
      } catch (error) {
        reject(error);
      };
    });
  },
  downloadReport: (
    customerInfo,
    searchedParams,
    userInfo,
    assignedUserList
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${ReportsConstant.REPORT_DOWNLOAD}`,
          {
            customerInfo: customerInfo,
            searchedParams: searchedParams,
            userInfo: userInfo,
            assignedUserList: assignedUserList,
          }
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      };
    });
  },
  getReportList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(`${ReportsConstant.REPORT_LIST_URL}`);
        resolve(response.data);
      } catch (error) {
        reject(error);
      };
    });
  },
  getCallLogReport: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(`${ReportsConstant.CALL_LOG_REPORT_URL}`, data);
        resolve(response.data);
      } catch (error) {
        reject(error);
      };
    });
  },
  getCallLogReportList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(ReportsConstant.CALL_LOG_LIST_URL);
        resolve(response.data);
      } catch (error) {
        reject(error);
      };
    });
  },
  createUnassignBatchLog: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(ReportsConstant.UNASSIGN_BATCH, data);
        resolve(response.data);
      } catch (error) {
        reject(error);
      };
    });
  },
  getUnassignBatchLog: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(ReportsConstant.UNASSIGN_BATCH_LOGS);
        resolve(response.data);
      } catch (error) {
        reject(error);
      };
    });
  }
};

export default ReportsService;
