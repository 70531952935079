import CryptoJS from "crypto-js";
import { spoctoPrivKey } from "constants/EncryptConstant";

export function groupManagement(userInfo, assignedUserList) {
  switch (userInfo.role) {
    case "Agent":
      return userInfo.username;
    case "Team Leader":
      return assignedUserList;
    case "Supervisor":
      return assignedUserList;
    case "Department Head":
      return assignedUserList;
    default:
      return "";
  }
}

export const encryptData = (data) => {
  if (!data) return false;
  const encryptedData = CryptoJS.AES.encrypt(data, spoctoPrivKey).toString();
  return encryptedData;
};

export const decryptData = (encryptedData) => {
  if (!encryptedData) return false;
  const decryptedData = CryptoJS.AES.decrypt(encryptedData, spoctoPrivKey);
  return decryptedData.toString(CryptoJS.enc.Utf8);
};
//application name -- relavant while fetching group name and company id as well
