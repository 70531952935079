import React from "react";
import { Form, Input, DatePicker, Select } from "antd";

const { Option } = Select;
const selectOptions = [];

export const DynamicInput = ({ elm }) => {
  let input;
  elm.type === "select" &&
    elm.options.forEach((elm, i) => {
      selectOptions.push(
        <Option key={elm} value={elm}>
          {elm}
        </Option>
      );
    });

  switch (elm.type) {
    case "text":
      input = (
        <Form.Item name={elm.name} label={elm.label} rules={elm.rule}>
          <Input
            placeholder={`Please enter ${elm.name}`}
            onKeyPress={(event) => {
              if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>
      );
      break;
    case "date":
      input = (
        <Form.Item name={elm.name} label={elm.label} rules={elm.rule}>
          <DatePicker />
        </Form.Item>
      );
      break;
    case "select":
      input = (
        <Form.Item name={elm.name} label={elm.label}>
          <Select placeholder="Select an option" allowClear>
            {selectOptions}
          </Select>
        </Form.Item>
      );
      break;
    default:
      input = null;
  }
  return input;
};
