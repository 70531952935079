import {
  LOAD_AGENT_LIST,
  LOAD_ASSIGNED_USER_LIST,
  SET_USER_ACTIVITY_STATUS,
  LOGIN_EVENT_ID,
  BREAK_EVENT_ID,
  SET_BREAK_EVENT_ID,
  SET_USER_ASSIGNED_CUSTOMER_DETAIL,
} from "../constants/User";
import { decryptData } from "utils/user-manage";
import { SMARTDIAL } from "redux/constants/Crm";

const initState = {
  agentList: "",
  loading: false,
  assignedUserList: "",
  userAssignedCustomerDetail: "",
  userActivityStatus: "active",
  loginEventId: localStorage.getItem(LOGIN_EVENT_ID),
  breakEventId: localStorage.getItem(BREAK_EVENT_ID),
  vicidialServerIp: decryptData(sessionStorage.getItem(SMARTDIAL)),
};

const user = (state = initState, action) => {
  switch (action.type) {
    case LOAD_AGENT_LIST:
      return {
        ...state,
        agentList: action.agentList,
      };
    case LOAD_ASSIGNED_USER_LIST:
      return {
        ...state,
        assignedUserList: action.assignedList,
      };
    case SET_USER_ACTIVITY_STATUS: {
      return {
        ...state,
        userActivityStatus: action.status,
      };
    }
    case SET_USER_ASSIGNED_CUSTOMER_DETAIL: {
      let viciServerIp;
      if (action.customerDetail.length !== 0) {
        viciServerIp = action.customerDetail[0].dialer_ip;
      }
      return {
        ...state,
        userAssignedCustomerDetail: action.customerDetail,
        vicidialServerIp: viciServerIp,
      };
    }
    case SET_BREAK_EVENT_ID: {
      return {
        ...state,
        breakEventId: action.breakEventId,
      };
    }

    default:
      return state;
  }
};

export default user;
