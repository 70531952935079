import HttpClient from "../utils/http-client";
import UserConstant from "constants/UserConstant";
import CrmConstant from "constants/CrmConstant"

const UserService = {
  addUser: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${UserConstant.ADD_USER}`,
          values
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addRole: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${UserConstant.ADD_ROLE}`,
          values
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addGroup: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${UserConstant.ADD_GROUP}`,
          values
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editUser: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${UserConstant.EDIT_USER}`,
          values
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  userList: (from, size, searchedParams) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${UserConstant.USER_LIST}?from=${from}&size=${size}`,
          searchedParams
        );
        resolve(response.data.users);
      } catch (error) {
        reject(error);
      }
    });
  },
  agentList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(`${UserConstant.AGENT_LIST}`);
        resolve(response.data.users);
      } catch (error) {
        reject(error);
      }
    });
  },
  logUserEvent: ({ userName, eventName, id, logTime ,vicidialServerIp }) => {
    const data = {
      userName,
      eventName,
      id,
      logTime,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${UserConstant.LOG_USER_EVENT}`,
          data
        );
        if(logTime === 'Start' && eventName !== "Login")
        {
          HttpClient.get(
            `${CrmConstant.PAUSE_USER}?username=${userName}&vicidialServerIp=${vicidialServerIp}&status='PAUSE'`
          );
        }
        else if(logTime === 'End' && eventName !== "Login")
        {
          HttpClient.get(
            `${CrmConstant.PAUSE_USER}?username=${userName}&vicidialServerIp=${vicidialServerIp}&status='RESUME'`
          );
        }
        resolve(response.data.id);
      } catch (error) {
        reject(error);
      }
    });
  },
  roleList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(UserConstant.ROLE_LIST);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  groupList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(UserConstant.GROUP_LIST);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default UserService;
