import {
  DashboardOutlined,
  TeamOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  FileSyncOutlined,
  FileDoneOutlined,
  ReconciliationOutlined
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const appsNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "CRM",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboard",
        path: `${APP_PREFIX_PATH}/crm/dashboard`,
        title: "Dashboard",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
        role: [ "Agent", "Team Leader", "Supervisor", "Admin", "Department Head" , "Super Admin"]
      },
      {
        key: "calendar",
        path: `${APP_PREFIX_PATH}/crm/calendar`,
        title: "Calendar",
        icon: CalendarOutlined,
        breadcrumb: false,
        submenu: [],
        role: [ "Agent", "Team Leader", "Supervisor", "Admin", "Department Head","Super Admin" ]
      },
      {
        key: "todos",
        path: `${APP_PREFIX_PATH}/crm/todos`,
        title: "To-do List",
        icon: ReconciliationOutlined,
        breadcrumb: false,
        submenu: [],
        role: [ "Agent", "Team Leader", "Supervisor", "Admin", "Department Head" ,"Super Admin"]
      },
      {
        key: "reports",
        path: `${APP_PREFIX_PATH}/crm/reports`,
        title: "Reports",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apr",
            path: `${APP_PREFIX_PATH}/crm/reports/apr`,
            title: "APR",
            breadcrumb: false,
            submenu: [],
            role: [ "Team Leader", "Supervisor","Admin", "Department Head" ,"Super Admin"]
          },
          {
            key: "call-log-report",
            path: `${APP_PREFIX_PATH}/crm/reports/call-log`,
            title: "Call Log Reports",
            breadcrumb: false,
            submenu: [],
            role: [ "Team Leader", "Supervisor", "Admin", "Department Head","Super Admin" ]
          },
          {
            key: "report",
            path: `${APP_PREFIX_PATH}/crm/reports/contact-log`,
            title: "Contact List Reports",  
            breadcrumb: false,
            submenu: [],
            role: [ "Team Leader", "Supervisor","Admin", "Department Head" ,"Super Admin"]
          },
          {
            key: "unassign-batch",
            path: `${APP_PREFIX_PATH}/crm/reports/unassign-batch`,
            title: "Unassign Batch",  
            breadcrumb: false,
            submenu: [],
            role: [ "Team Leader", "Supervisor","Admin", "Department Head","Super Admin" ]
          }

        ],
        role: [ "Team Leader", "Supervisor","Admin", "Department Head" ,"Super Admin"]
      },
  
      {
        key: "data-administration",
        path: `${APP_PREFIX_PATH}/crm/data-administration`,
        title: "Data Administration",
        icon: FileSyncOutlined,
        breadcrumb: false,
        submenu: [],
        role: [ "Team Leader", "Supervisor", "Admin", "Department Head" ,"Super Admin"]
      },
      {
        key: "users-roles",
        path: `${APP_PREFIX_PATH}/users`,
        title: "Users & Roles",
        icon: TeamOutlined,
        breadcrumb: true,
        submenu: [],
        role: [ "Super Admin" ]
      },
    ],
  },
];

const navigationConfig = [...appsNavTree];



export default navigationConfig;
