import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import Views from "./views";
import { Route, Switch, HashRouter } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <HashRouter>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </HashRouter>
      </Provider>
    </div>
  );
}

export default App;
