import {
  LOAD_CONTACT_LIST,
  LOAD_CONTACT_TRACE_DATA,
  LOAD_CONTACT_ENGAGE_DATA,
  SET_SELECTED_CONTACT_ROW,
  SET_START_CALL,
  SHOW_DISPOSITION_PANEL,
  HIDE_DISPOSITION_PANEL,
  LOAD_CALL_HISTORY,
  UPDATE_CALL_HISTORY,
  SET_TOTAL_PAGE_ROWS,
  SHOW_LOADING,
  HIDE_LOADING,
  SET_VICI_CALL_LOG,
  LOAD_DASHBOARD,
  SET_SEARCHED_PARAMS,
  TOGGLE_CONTACT_DETAIL_EDITABLE,
  SET_MODIFIED_CONTACT_DETAIL_DATA,
  UPDATE_CONTACT_TRACE_DATA,
  SET_INBOUND_CALL_DATA,
  SET_CONTACT_INFO_DATA,
  SET_CURRENT_PAGE,
  LOAD_DISPOSITION_LIST,
} from "../constants/Crm";

export function loadContactList(contactList) {
  return {
    type: LOAD_CONTACT_LIST,
    contactList,
  };
}

export function loadContactTraceData(traceData) {
  return {
    type: LOAD_CONTACT_TRACE_DATA,
    traceData,
  };
}

export function loadContactEngageData(engageData) {
  return {
    type: LOAD_CONTACT_ENGAGE_DATA,
    engageData,
  };
}

export function setSelectedRow(selectedRow) {
  return {
    type: SET_SELECTED_CONTACT_ROW,
    selectedRow,
  };
}

export function setStartCall(callId, callType) {
  return {
    type: SET_START_CALL,
    payload: { callId, callType },
  };
}

export function showDispositionPanel() {
  return {
    type: SHOW_DISPOSITION_PANEL,
  };
}

export function hideDispositionPanel() {
  return {
    type: HIDE_DISPOSITION_PANEL,
  };
}

export function loadCallHistory(callHistory) {
  return {
    type: LOAD_CALL_HISTORY,
    callHistory,
  };
}

export function updateCallHistory(callHistory) {
  return {
    type: UPDATE_CALL_HISTORY,
    callHistory,
  };
}

export function setTotalPageRows(key, total) {
  return {
    type: SET_TOTAL_PAGE_ROWS,
    payload: { key, total },
  };
}

export function setCurrentPage(key, page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: { key, page },
  };
}

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const setViciCallLog = (viciCallLog) => {
  return {
    type: SET_VICI_CALL_LOG,
    viciCallLog,
  };
};

export const loadDashoard = (dashboard) => {
  return {
    type: LOAD_DASHBOARD,
    dashboard,
  };
};

export const setSearchedParams = (searchedParams) => {
  return {
    type: SET_SEARCHED_PARAMS,
    searchedParams,
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
  };
};

export const toggleContactDetailEditable = (status) => {
  return {
    type: TOGGLE_CONTACT_DETAIL_EDITABLE,
    status,
  };
};

export const setModifiedContactDetailData = (data) => {
  return {
    type: SET_MODIFIED_CONTACT_DETAIL_DATA,
    data,
  };
};

export const updateContactTraceData = (data) => {
  return {
    type: UPDATE_CONTACT_TRACE_DATA,
    data,
  };
};

export const setInboundCallData = (data) => {
  return {
    type: SET_INBOUND_CALL_DATA,
    data,
  };
};

export const setContactInfo = (data) => {
  return {
    type: SET_CONTACT_INFO_DATA,
    data,
  };
};

export const loadDispositionList = (list) => {
  return {
    type: LOAD_DISPOSITION_LIST,
    list,
  };
};
