let BASE_URL;
if (window.location.hostname === "localhost") {
  BASE_URL = `http://localhost:3003/api`;
} else {
  BASE_URL = `/api`;
  
}

const ReportsConstant = {
  GET_APR_REPORT: `${BASE_URL}/reports/apr`,
  APR_REPORT_LIST: `${BASE_URL}/reports/apr-list`,
  REPORT_DOWNLOAD: `${BASE_URL}/reports/report-download`,
  REPORT_LIST_URL: `${BASE_URL}/reports/get-report-list`,
  CALL_LOG_REPORT_URL: `${BASE_URL}/reports/call-log-report`,
  CALL_LOG_LIST_URL: `${BASE_URL}/reports/call-log-report-list`,
  UNASSIGN_BATCH: `${BASE_URL}/reports/unassign-batch`,
  UNASSIGN_BATCH_LOGS: `${BASE_URL}/reports/unassign-batch-logs`
};

export default ReportsConstant;