import React, { Component } from "react";
import { Select, Row, Tag } from 'antd';
import CrmService from "services/CrmService";

const { Option } = Select;

let timeout;
let currentValue;

function fetch(value, callback) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    currentValue = value;

    function fake() {
        //const str = querystring.encode({ code: "utf-8", q: value });

        CrmService.getNavSearch(0, 10, value)
        .then(d => {
            if (currentValue === value) {
                const result = d.hits;
                const data = [];
                result.forEach( r => {
                    data.push({ ...r._source, _id: r._id });
                });
                callback(data);
            }
        });
    }

    timeout = setTimeout(fake, 300);
}

class SearchInput extends Component {
    state = {
        data: [],
        value: undefined,
    };

    handleSearch = value => {
        if (value) fetch(value, data => this.setState({ data }));
        else this.setState({ data: [] });
    };

    handleChange = value => this.setState({ value });

    render() {
        const options = this.state.data.map(d => <Option key={ d._id }><Tag color="#001529">Full Name: { d.full_name } </Tag><Tag color="#cd201f">Loan Account No: { (d.loan_account_no) }</Tag></Option>);
        return (
            <Row gutter={ 16 }>
                <Select
                    showSearch
                    value={this.state.value}
                    placeholder="Search..."
                    style={{ width: 500, marginTop: 0, paddingTop: 0, top: !this.props.isMobile && -15 }}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    notFoundContent={null}
                    disabled
                >
                    {options}
                </Select>
            </Row>
        );
    }
}

export default SearchInput