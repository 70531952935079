let BASE_URL;
if (window.location.hostname === "localhost")
  BASE_URL = `http://localhost:3003/api`;
else {
  BASE_URL = `/api`;
  
}

const CrmConstant = {
  CONTACT_LIST_URL: `${BASE_URL}/crm/contact-list`,
  CONTACT_DETAIL_URL: `${BASE_URL}/crm/contact-detail`,
  CONTACT_UPDATE_URL: `${BASE_URL}/crm/update-contact-list`,
  FILE_IMPORT_URL: `${BASE_URL}/crm/import-file`,
  FILE_LIST_URL: `${BASE_URL}/crm/get-file-list`,
  FILE_MAPPING_URL: `${BASE_URL}/crm/add-file-mappings`,
  KALEYRA_CALL_URL: `https://api-voice.kaleyra.com/v1/`,
  CALL_LOG_URL: `${BASE_URL}/crm/create-call-log`,
  CALL_HISTORY_URL: `${BASE_URL}/crm/call-history`,
  ENGAGE_DATA_URL: `${BASE_URL}/crm/engage-data`,
  KALEYRA_CALLBACK: `${BASE_URL}/crm/kaleyra-callback`,
  DASHBOARD_DATA: `${BASE_URL}/crm/dashboard`,
  NAV_SEARCH_URL: `${BASE_URL}/crm/nav-search`,
  UPDATE_CONTACT_DETAIL: `${BASE_URL}/crm/update-contact-detail`,
  VICIDIAL_CALL_LOG: `${BASE_URL}/vicidial/vici-calllog`,
  CALL_CONTACT: `${BASE_URL}/vicidial/calling-contact`,
  PAUSE_USER: `${BASE_URL}/vicidial/pause-user`,
  HANGUP_URL: `${BASE_URL}/vicidial/hangup-contact`,
  CAMPAIGN_DIAL_METHOD: `${BASE_URL}/vicidial/vici-dialmethod`,
};

export default CrmConstant;
