import React, { useState, useEffect } from "react";
import { Tag, Select, Modal, message } from "antd";
import ReactStopwatch from "react-stopwatch";
import { connect } from "react-redux";
import { setUserActivityStatus, setBreakEventId } from "redux/actions/User";
import UserService from "services/UserService";
import { BREAK_EVENT_ID } from "redux/constants/User";

const { Option } = Select;

const UserEvents = ({
  userInfo,
  userActivityStatus,
  breakEventId,
  setUserActivityStatus,
  setBreakEventId,
  startCall,
  vicidialServerIp,
}) => {
  const [visible, setVisible] = useState(false);
  const [breakType, setBreakType] = useState("");
  const Events = [
    "Tea Break",
    "Lunch Break",
    "Briefing Break",
    "Training Break",
    "Feedback Break",
    "Comfort Break",
  ];

  const logEvent = (time) => {
    UserService.logUserEvent({
      userName: userInfo.username,
      eventName: breakType,
      id: breakEventId,
      logTime: time,
      vicidialServerIp: vicidialServerIp,
    }).then((res) => {
      setBreakEventId(res);
      localStorage.setItem(BREAK_EVENT_ID, res);
    });
  };

  const onOpen = () => {
    setBreakType("");
    setVisible(true);
  };

  const handleOk = () => {
    if (!breakType) {
      message.error("Please Select a Break Event");
      return false;
    } else {
      setUserActivityStatus("onBreak");
      logEvent("Start");
    }
  };

  const handleCancel = () => {
    setUserActivityStatus("active");
    setVisible(false);
    if (breakEventId) {
      logEvent("End");
    }
  };

  useEffect(() => {
    const breakEvent = localStorage.getItem(BREAK_EVENT_ID);
    if (breakEvent && breakEvent !== "undefined") {
      setVisible(true);
      setUserActivityStatus("onBreak");
    }
    return () => {
      setUserActivityStatus("active");
      setVisible(false);
      setBreakEventId("");
    };
  }, []);

  const onBreakChange = (value) => {
    setBreakType(value);
  };
  return (
    <>
      {startCall ? (
        <span
          className="callActiveStatus"
          style={{ color: "green", fontWeight: "bold" }}
        >
          LIVE CALL
        </span>
      ) : (
        <span
          className="callInactiveStatus"
          style={{ color: "grey", fontWeight: "bold" }}
        >
          NO CALL
        </span>
      )}

      {userActivityStatus === "active" ? (
        <Tag
          color="green"
          onClick={onOpen}
          style={{ marginTop: "24px", marginLeft: "20px" }}
        >
          Active
        </Tag>
      ) : (
        <Tag
          color="red"
          onClick={onOpen}
          style={{ marginTop: "24px", marginLeft: "20px" }}
        >
          Paused
        </Tag>
      )}

      <Modal
        title="Break Time"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Break Over"
        okText="Start Break"
        width="350px"
        centered
        closable={false}
        maskClosable={false}
      >
        {userActivityStatus !== "onBreak" ? (
          <Select
            showSearch
            allowClear
            placeholder="Select Break Time"
            style={{ width: "80%" }}
            onChange={onBreakChange}
          >
            {Events.map((elm, i) => (
              <Option key={i} value={elm} label={elm}>
                {elm}
              </Option>
            ))}
          </Select>
        ) : (
          <ReactStopwatch
            seconds={0}
            minutes={0}
            hours={0}
            render={({ formatted }) => {
              return (
                <div>
                  <h1 style={{ textAlign: "center" }}>{formatted}</h1>
                </div>
              );
            }}
          />
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = ({ user, auth, crm }) => {
  const { breakEventId, userActivityStatus, vicidialServerIp } = user;
  const { userInfo } = auth;
  const { startCall } = crm;
  return {
    breakEventId,
    userInfo,
    userActivityStatus,
    startCall,
    vicidialServerIp,
  };
};

export default connect(mapStateToProps, {
  setUserActivityStatus,
  setBreakEventId,
})(UserEvents);
