export const spoctoPrivKey = `MIIG5AIBAAKCAYEAxjz5hxx26vniB6m7ojT8MX5KuyFc1PGYtUZ/RA65r9uvZPsu
QBw1KXmorro0YEbczc4JrPFWQW3YQE8ZVSV/zfwWndF2Rg1ynmpicfolVWxe1IyN
KASHra5pgNSwO95KMg7+Mz904VIy3j9iXictWtXDHu8W3WMDqPpiDN7hN3u6Gysa
vEiArPb2UlEb3Wb9GE7GA03oW6m5CxmqJuO3UJPJZaKwqNcASTXgDde+E00V2m8s
mjubhKcM4g4y9FSDONncUeI1/FI7hKYvQRCLZCZQsElm7wlu9xkIMJKlRqUhOs8e
UemR6cyjzveOswkBSMCxl7Ko40yXIfFw/fJDjo5tcZK5tDqYf/xNP9a0RHcaexxR
17erFNcilqU3amm5bHD7tfA8u8bIh/THHmQgmqitniAPCwcrNxrzzHmCl0ylLSLt
p3kDLiZa9iosCszYAgQIry4oWtRhd7lx43471LXG7u+TKep0GxzOskAg/YJBM5cH
c2EUd55OfwnDdl5BAgMBAAECggGAMgZNCZVbROyN51UjrkkkovC1twcyP+I/oO8L
SodUwFX1qsvf3fnASJkUwhLnCKyZJ64oruWv2I75lTDguaXe3IISyvyClibql6NM
+GohZa8VNxnIvSJ3gpKSuRny5wivPHagGxqWSg3OHFtuXUgRn+x1cqbu5CsKT2fv
ukO8HZ4xVo3ID19UaVFg02D4YSSOFcY7HbZZPlhUA8MUNwAb0E+a8PTfE5w9zDu2
MIgSxRgcvaR0Yu9LzWvCSoE+xn1UthJy49cftqF5jTSlDOH/FyYn++3fNCEgHd2j
sjlAIN2QQFHzd2eSWi0rAD+GXjgXhwvqvOM1PiCLgY8yWeZnuyezltzvjQVNdSou
skkfuUfyfkZVep8B69uiS3+Yek6f5Tze9yQtV6KXcYG38/E1kmij30H9Fo0awhfx
dlu85VCvN34xjx+Ilezqhji1tGNAAzmwNp2PPaAhiFkutXNY+2/8EYfcK0S504nH
rJcnpCq1iUphw4fJEnttI1Qj523hAoHBAOgrkpDFOyzsZ4NX5QKqWy5d2ibOqSo9
ADTdsYaaw+wQamYdaxXXwgV4R6lHTJFRhUD+W5fns9HUX2lZIqlG8SbY0EoYMaQ/
I3YDCWxLxrTdfqOwrHy7tn8RnO5Iqwx7xQ9bZQX9vQxzxwtI0ndteHMB9DGidXOK
1zt9YkmoqfkaXLHIB0uRDJSiX5g9sJ1oEU9EdoyOgXJlmeDu3PS/+iisyWM+BKtC
YRJd5DB8FZIUlJBSgg6eyCDU9aGufirt3QKBwQDaldDLBRYWvWiJLo2WPp+FW/8A
xEhHgJw43tb9uouInyk55V+/iVFN8CiML4Vwx2a+bQQpftO8Veu3ESvbPH6POM5+
fwuLxitZa4lqORJ9H2LXr7KXUNYrfwatYOn1HcCKVaPAdjDdu5l+guGmDDY7TpRl
/cu8qhidrM6ekUSWLz2spqPmjfhPj+zUWpLQufAtCoAU2bPMrBdsxNaGYSf2wVP1
ssTHhSqm3AeaRcJd7MQDBm6UAmer8m+D1ZIUZbUCgcAD0aOpifT9Oi5BtNJphs/V
JWoPNVsG/NXiCLuA0U7jtSlq4JUAWPoyCNZ07F1QDp04gnaTOxZ0HyAqpXKygh45
W1uhvGAkqX011brz8xMjqnkIzEEqFBReZrXEHaHipK6LIB+7R38ykzz07I+5sy1G
BwQzRM7VxbTgn6FcQM/67voTWoz0K0CfuUQFRw5+qFWQjjm8+iMa9JzhXEx6IiZF
QQJXBZAiGQdru+ui+IeKTZhb/19wSiBUmewoaqxcnGkCgcEAvIYySqfcYUiDfK3W
GBty4kZyLWsdG+5kzvgDXVsjNXZwzCp05nG82gekMSRbZuXUS7EiwddNL0a7wmzg
8nm+fjB0iM1yW99TTBBfuGn49Em5qmUzFyb1URaB38+SeYJrqeQrE7fcdaFzxZWE
oP4vV/9yJnU630GlcXi1zDVmflHIU7x16EfSPmzXyl9D+Rold/NyfIk3dfOHUJyE
men52ezsSSEibyL/omJrfDdmaDPo6uMFREq+t6KlY4M0NrtxAoHBAL941VGZeOH/
KJ2cb8Ot6bBbuuHtmlLB6cjSrzPkGdoajCTVfh/k69JJwTvQR0AcAA9Er5WSWKpf
f7hR7qw629rM0fcGlIO5++JnQL7Mr8GrvT0NtWUl+i6zqbaazwe+GVmjvviVU/iM
CCrTRK/BLGAuAHRegQdsjfvYxFs0VLi5W3a8LrWvrYGeeFkdRDf0wa2bm2ymDkKB
YQdZGuhJx9ZcUi9+q7wXufk5IYDmCffSXFTQJY+iZgzYXZB9FJEFJA==`;