import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SHOW_LOADING,
  USER_INFO,
  EXPIRES_AT,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SHOWOTPFORM,
  VICIDIAL_AUTHENTICATED,
  IS_VICI_LOGGEDIN,
  LOAD_CUSTOMER_LIST,
  VICIDIAL_USER_INFO,
} from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  userInfo: JSON.parse(localStorage.getItem(USER_INFO)),
  token: localStorage.getItem(AUTH_TOKEN),
  expiresAt: localStorage.getItem(EXPIRES_AT),
  isLoginFormVisible: true,
  vicidialUserInfo: JSON.parse(localStorage.getItem(VICIDIAL_USER_INFO)),
  isViciLoggedIn: localStorage.getItem(IS_VICI_LOGGEDIN),
  customerList: "",
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: "/",
        token: action.user.authToken,
        userInfo: action.user.userInfo,
      };
    case SHOWOTPFORM:
      return {
        ...state,
        isLoginFormVisible: action.status,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loading: false,
        isLoginFormVisible: true,
        isViciLoggedIn: false,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        redirect: "/",
        token: action.user.authToken,
        userInfo: action.user.userInfo,
      };
    }
    case VICIDIAL_AUTHENTICATED: {
      return {
        ...state,
        vicidialUserInfo: action.viciUserInfo,
        isViciLoggedIn: true,
      };
    }
    case LOAD_CUSTOMER_LIST: {
      return {
        ...state,
        customerList: action.customerList,
      };
    }
    default:
      return state;
  }
};

export default auth;
