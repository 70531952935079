let BASE_URL;
if (window.location.hostname === "localhost") {
  BASE_URL = `http://localhost:3003/api`;
} else {
  BASE_URL = `/api`;
}

const SharedConstant = {
  CUSTOMER_LIST: `${BASE_URL}/shared/customer-list`,
  COUNTRYCODE_LIST_URL: `${BASE_URL}/shared/countrycode-list`,
  ASSIGNED_USER_LIST: `${BASE_URL}/shared/assigned-user-list`,
  USER_ASSIGNED_CUSTOMER_LIST: `${BASE_URL}/shared/user-assigned-customer-list`,
  CAMPAIGN_LIST: `${BASE_URL}/vicidial/campaign-list`,
  DISPOSITION_LIST_URL: `${BASE_URL}/shared/disposition-list`,
};

export default SharedConstant;