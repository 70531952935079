import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  USER_INFO,
  EXPIRES_AT,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_OTP,
  USER_EMAIL,
  VICIDIAL_LOGIN,
  IS_VICI_LOGGEDIN,
} from "../constants/Auth";
import { LOGIN_EVENT_ID } from "redux/constants/User";
import {
  showAuthMessage,
  authenticated,
  showOtpForm,
  signOutSuccess,
  signInWithGoogleAuthenticated,
  vicidialAuthenticated,
} from "../actions/Auth";
import { AuthService, CrmService } from "services";
import UserService from "services/UserService";

export function* signInWithEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    try {
      const user = yield call(AuthService.signin, payload);
      if (user.message) {
        console.log("in email", user.message);
        yield put(showAuthMessage(user.message));
      } else {
        if (user?.otplogin === true) {
          localStorage.setItem(USER_EMAIL, user.userInfo.email);
          yield put(showOtpForm(false));
        } else {
          localStorage.setItem(AUTH_TOKEN, user.authToken);
          localStorage.setItem(USER_INFO, JSON.stringify(user.userInfo));
          localStorage.setItem(EXPIRES_AT, user.expiresAt);
          yield put(authenticated(user));
          //Aaina Log User login Time
          const id = yield call(UserService.logUserEvent, {
            userName: user.userInfo.username,
            eventName: "Login",
            id: "",
            logTime: "Start",
          });
          if (id) {
            localStorage.setItem(LOGIN_EVENT_ID, id);
          }
        }
      }
    } catch (err) {
      yield put(showAuthMessage(err ? err.message : "Invalid Credentials"));
    }
  });
}

export function* signInToVicidial() {
  yield takeEvery(VICIDIAL_LOGIN, function* ({ payload }) {
    try {
      const vici = yield call(AuthService.vicidialLogin, payload);
      if (vici.message) {
        yield put(showAuthMessage(vici.message));
      } else {
        yield put(vicidialAuthenticated(vici));
        if (payload.userInfo.role === "Agent") {
          if (vici.server_ip) {
            window.open(
              `http://${vici.server_ip}/agc/vicidial.php?phone_login=${vici.phone_login}&phone_pass=${vici.phone_pass}&VD_login=${vici.user}&VD_pass=${vici.pass}&VD_campaign=${payload.campaign}`
            );
            localStorage.setItem(IS_VICI_LOGGEDIN, true);
            yield call(CrmService.campaignDialMethod, payload);
          } else {
            localStorage.setItem(IS_VICI_LOGGEDIN, false);
            yield put(
              showAuthMessage("User Not Configured Properly in SmartDial")
            );
          }
        }
      }
    } catch (err) {
      yield put(showAuthMessage(err ? err.message : "Vicidial Login Failed"));
    }
  });
}

export function* signInWithOtp() {
  yield takeEvery(SIGNIN_OTP, function* ({ payload }) {
    try {
      const user = yield call(AuthService.signinOtp, payload);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.authToken);
        localStorage.setItem(USER_INFO, JSON.stringify(user.userInfo));
        localStorage.setItem(EXPIRES_AT, user.expiresAt);
        yield put(authenticated(user));
      }
    } catch (err) {
      yield put(showAuthMessage("Invalid OTP"));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* ({ payload }) {
    try {
      const user = JSON.parse(localStorage.getItem(USER_INFO));
      const loginEventId = localStorage.getItem(LOGIN_EVENT_ID);

      //Aaina Log User logout Time
      if (loginEventId) {
        yield call(UserService.logUserEvent, {
          userName: user?.username,
          eventName: "Login",
          id: localStorage.getItem(LOGIN_EVENT_ID),
          logTime: "End",
        });
      }
      yield put(signOutSuccess());
      localStorage.clear();
      if (payload && user.role === "Agent") {
        yield call(AuthService.viciLogout, payload);
      }
    } catch (err) {
      localStorage.clear();
      sessionStorage.clear();
      yield put(signOutSuccess());
      //yield put(showAuthMessage(err));
    }
  });
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* ({ payload }) {
    try {
      const user = yield call(AuthService.googleSignin, payload);

      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.authToken);
        localStorage.setItem(USER_INFO, JSON.stringify(user.userInfo));
        localStorage.setItem(EXPIRES_AT, user.expiresAt);
        yield put(signInWithGoogleAuthenticated(user));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signInWithEmail),
    fork(signOut),
    fork(signInWithGoogle),
    fork(signInWithOtp),
    fork(signInToVicidial),
  ]);
}
