import React from "react";
import { connect } from "react-redux";
import { Select, Form } from "antd";

const { Option } = Select;

const DispositionDropdown = ({
  dispositionlist,
  mode,
  onChangefunction,
  placeholder,
  rules,
  label,
  name,
}) => {
  const getDashboardList = () => {
    const dashboardList = [];
    dispositionlist.forEach((value, key) => {
      dashboardList.push({
        value: key,
        label: value.label,
      });
    });

    return dashboardList;
  };

  const list = getDashboardList();

  return (
    <>
      <Form.Item
        name={name ? name : "disposition"}
        label={label ? label : ""}
        rules={
          rules
            ? [{ required: true, message: "Please choose Disposition" }]
            : ""
        }
      >
        <Select
          showSearch
          allowClear
          placeholder={placeholder}
          optionFilterProp="label"
          size="medium"
          mode={mode}
          onChange={onChangefunction}
        >
          {list.map((elm) => (
            <Option key={elm.value} value={elm.value} label={elm.label}>
              {elm.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

const mapStateToProps = ({ crm }) => {
  const { dispositionlist } = crm;
  return { dispositionlist };
};

export default connect(mapStateToProps)(DispositionDropdown);
