import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { vicidialLogin, loadCustomerList } from "redux/actions/Auth";
import {
  loadAssignedUserList,
  setUserAssignedCustomerDetail,
} from "redux/actions/User";
import SharedService from "services/SharedService";
import ViciLogin from "components/shared-components/Modal/ViciLogin";
import socketIOClient from "socket.io-client";
import {
  setInboundCallData,
  setStartCall,
  setContactInfo,
} from "redux/actions/Crm";
import { IS_CALL_ACTIVE , SMARTDIAL } from "redux/constants/Crm";
import { encryptData } from "utils/user-manage";

const ENDPOINT = `http://${window.location.hostname}`;

export const AppViews = (props) => {
  const {
    userInfo,
    isViciLoggedIn,
    loadCustomerList,
    loadAssignedUserList,
    setUserAssignedCustomerDetail,
    setStartCall,
    setInboundCallData,
    setContactInfo,
    startCall,
  } = props;

  const history = useHistory();

  //Load CustomerList and AgentList As Soon as Application is Loaded
  useEffect(() => {
    const fetchInitialData = async () => {

      if (!sessionStorage.getItem(SMARTDIAL)) {
        SharedService.userAssignedCustomerList(userInfo.customerid).then(
          (res) => {
            if (res.status === 200) {
              sessionStorage.setItem(
                SMARTDIAL,
                encryptData(res.customerList[0].dialer_ip)
              );
              setUserAssignedCustomerDetail(res.customerList);
            }
          }
        );
      }
      SharedService.customerList().then(({ customerList }) => {
        loadCustomerList(customerList);
      });

      SharedService.assignedUserList(userInfo.owner_group, userInfo.role).then(
        (res) => {
          const response = [...res, { username: userInfo.username }];
          loadAssignedUserList(response);
        }
      );
    };
    fetchInitialData();
  });

  useEffect(() => {
    function goToDetailPage(loan_account_no, batch_no, customerid, index) {
      const data = {
        loan_account_no,
        batch_no,
        customerid,
        index,
      };
      localStorage.setItem("CONTACT_INFO", btoa(JSON.stringify(data)));
      setContactInfo(data);
      history.push(`/app/crm/contact-detail`);
    }

    if (userInfo.role === "Agent" && !startCall) {
      const socket = socketIOClient(ENDPOINT);
      socket.on(userInfo.username, (data) => {
        setStartCall("", "Inbound");
        setInboundCallData(data);
        goToDetailPage(
          data.loan_account_no,
          data.batch_no,
          data.customerid,
          9999
        );
        sessionStorage.setItem(IS_CALL_ACTIVE, "Inbound");
      });
      socket.on("connect_error", (err) => {
        console.log(
          `connect_error due to socket connection failed ${err.message}`
        );
      });
      socket.on("connect", () => {
        // console.log(socket.id);
      });
      //CLEAN UP THE EFFECT
      return () => socket.disconnect();
    }
  }, [
    userInfo.role,
    history,
    setContactInfo,
    setInboundCallData,
    setStartCall,
    userInfo.username,
    startCall,
  ]);

  return (
    <>
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          <Route
            path={`${APP_PREFIX_PATH}/crm`}
            component={lazy(() => import(`./crm`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/users`}
            component={lazy(() => import(`./users`))}
          />
          <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/crm`} />
        </Switch>
      </Suspense>
      {!isViciLoggedIn && userInfo.role === "Agent" && <ViciLogin />}
    </>
  );
};

const mapStateToProps = ({ auth, crm }) => {
  const { userInfo, message, showMessage, isViciLoggedIn } = auth;
  const { startCall } = crm;
  return {
    userInfo,
    message,
    showMessage,
    isViciLoggedIn,
    startCall,
  };
};

export default React.memo(
  connect(mapStateToProps, {
    vicidialLogin,
    loadCustomerList,
    loadAssignedUserList,
    setUserAssignedCustomerDetail,
    setInboundCallData,
    setStartCall,
    setContactInfo,
  })(AppViews)
);
