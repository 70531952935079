import axios from "axios";
import { AUTH_TOKEN } from "../redux/constants/Auth";

//Axios Interceptor to add token in Header:

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const HttpClient = {
  get: (url) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(url);
        resolve(response);
      } catch (error) {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error.request.data);
        } else {
          reject(error);
        }
      }
    });
  },
  post: (url, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(url, data);
        resolve(response);
      } catch (error) {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error.request.data);
        } else {
          reject(error);
        }
      }
    });
  },
  // post: (url, data) => {
  //     return new Promise((resolve, reject) => {
  //         axios.post(url, data)
  //             .then((response) => resolve(response))
  //             .catch((error) => reject(error))
  //     });
  // },
  put: (url, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.put(url, data);
        resolve(response);
      } catch (error) {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error.request.data);
        } else {
          reject(error);
        }
      }
    });
  },

  delete: (url) => {},
};

export default HttpClient;
