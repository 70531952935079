import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Crm from "./Crm";
import User from "./User";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  crm: Crm,
  user: User,
});

export default (state, action) =>
reducers(action.type === 'SIGNOUT' ? undefined : state, action);