import AuthConstant from "../constants/AuthConstant";
import HttpClient from "../utils/http-client";
import { USER_EMAIL } from "redux/constants/Auth";

const AuthService = {
  register: (email, password) => {},
  signin: (user) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(AuthConstant.SIGNIN_URL, user);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  signinOtp: (otp) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          AuthConstant.SIGNIN_OTP_URL,
          otp
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  resendOtp: () => {
    const email = localStorage.getItem(USER_EMAIL);
    if (!email) return false;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(AuthConstant.RESEND_OTP_URL, {
          email: email,
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  googleSignin: (user) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(AuthConstant.SIGNIN_URL, user);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  vicidialLogin: (payload) => {
    const username = payload.userInfo && payload.userInfo.username;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          AuthConstant.VICIDIAL_LOGIN_URL,
          { username: username , vicidialServerIp: payload.vicidialServerIp }
        );
        resolve(response.data);
      } catch (error) {
        console.log("vicilogin" , error)
        reject(error);
      }
    });
  },
  permissionList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(`${AuthConstant.PERMISSION_LIST}`);
        const data = response.data.permissionList;
        const permission = [];
        data.forEach((elm) => {
          permission.push({
            id: elm._id,
            label: elm._source.label,
            name: elm._source.name,
            sublist: elm._source.sub_list.data || null,
            accesslist: [
              { Access: "View" },
              { Access: "Edit" },
              { Access: "Create" },
              { Access: "Delete" },
              { Access: "Export" },
            ],
          });
        });
        resolve(permission);
      } catch (error) {
        reject(error);
      }
    });
  },
  roleList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(`${AuthConstant.ROLE_LIST}`);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  groupList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(`${AuthConstant.GROUP_LIST}`);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  viciLogout: ({username,vicidialServerIp}) => {
    //Not Storing vicidial user info in locastorage
    //const username = vicidialUserInfo && vicidialUserInfo.user
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${AuthConstant.VICIDIAL_LOGOUT_URL}?username=${username}&vicidialServerIp=${vicidialServerIp}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updatePassword: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(AuthConstant.UPDATE_PASSWORD, data);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default AuthService;
